/* eslint-disable */
import React from 'react'
import styled from '@emotion/styled'
import { Global, css } from "@emotion/core";
import ReactPixel from 'react-facebook-pixel';
import Banner from '../contest/Banner/'
import Content from '../contest/Content/'
import Footer from '../contest/Footer/'
import Modal from '../contest/Modal/'
import Layout from '../components/layout'
import Header from '../components/header.js'

import sendEvent from '../helpers/sendAnalytics';
import Helmet from 'react-helmet'

import Loading from '../assets/images/loader.svg'

/* Contest Styles */
const ContestWrapper = styled.div(props => ({
  'font-family': 'museo-sans, sans-serif',
  'font-weight':'300',
  'line-height': '29px',
  'margin': '0',
  'padding': '0',
  'width': '100%',
  'min-height': '100vh',
  'height': 'auto',
  'display': 'block',
  'color': '#3c3c3c',
  'background-color': props.backgroundColor,
  'overflow-x':'hidden'
}))

const Contest = styled(ContestWrapper)`
  a {
    color: #000;
  }
  p {
    'font-family': 'museo-sans,sans-serif';
  }
`

const Loader = styled.div`
  width: 25px;
  height: 25px;
  position: fixed;
  z-index: 99999;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  background-image: url(${Loading});
  transform: rotate(45deg);
  animation-name: spin;
  animation-duration: 2.5s;
  opacity: 0.5;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  keyframes spin{
    from {transform:rotate(0deg)}
    to {transform:rotate(720deg)}
  }
`


class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.setValue = this.setValue.bind(this);
    this.state = {
      customer:{
        email: '',
        firstname: '',
        lastname: '',
        address: '',
        address2: '',
        phone: '',
        city: '',
        state: '',
        zipcode: '',
        yearofbirth: '',
        uuid: null,
      },
      validations:{
        terms:false,
        newsletter:true,
        roundtableMember:false,
        alreadyEntered: false,
        numberOfEntries:null,
        couponDownloaded:''
      },
      controls:{
        modal: false,
        modalStep: 1,
        menu: false,
        captcha: true,
        loader: false
      },
      screens:{
        bannerFinal:false,
        contentFinal:false,
      },
      tracking:{
        modal1:false,
        modal2:false,
        modal3:false,
        thanks:false,
        coupon:false,
        variant:null
      }
    }
  }

  setValue(key, value, callback){
    let obj = {};
    obj[key] = value;
    callback ? this.setState(obj, callback):this.setState(obj)
  }

  componentDidMount() {
    ReactPixel.init('385998442103264');
    ReactPixel.track( 'ViewContent', {} )
  }


  render() {

    return (
      <Layout
        meta={{
          title: 'Scotties - NFL Patriots Softer Side Of Strong'
        }}
        enRoute="/"
        frRoute="/"
        theme_color="#A6CE39"
      >
      <Header hideBuy={true} hideMenu={this.state.screens.bannerFinal ? false : true} whiteVersion={true} hideRetailer={true}></Header>
        <Contest>
          <Banner
            final={this.state.screens.bannerFinal}
            appState={this.state}
            setValue={this.setValue} />
          <Content
            final={this.state.screens.contentFinal}
            appState={this.state}
            setValue={this.setValue} />
          <Footer
            appState={this.state} />
          <Modal
            appState={this.state}
            setValue={this.setValue}
            visible={this.state.controls.modal} />
          <Loader style={{'display':this.state.controls.loader ? 'block' : 'none'}}/>
        </Contest>

      </Layout>
    )
  }
}

export default IndexPage
